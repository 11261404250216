import { graphql } from 'gatsby'
import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sidebar from '../../components/sidebar'
import Img from "gatsby-image"


const BasSurfaceColorisPage = ({data}) => {
   return(
  <Layout>
    <SEO title="Catalogue" />
 
    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <div class="grid grid-cols-1 md:col-span-3 md:mB-5 mx-3"> {/** first element */}
      <h1 class="text-2xl font-bold mb-2 md:mt-5">Coloris</h1>
      <div class="border-solid border-2 border-red-500 w-1/12 mb-3"></div>
            <div class="mt-3 gap-2 grid">
              { data.allFile.edges.map(({ node }) =>
                    <div class="coloris">
                        <Img class="bs-coloris-image" fluid={node.childImageSharp.full} />
                    </div>
              )}
            </div>
      </div>
      <div>
        <Sidebar />
      </div>
    </div>
  </Layout>
)
}
export const query = graphql`
query bsColoris {
  allFile (filter:{relativeDirectory: {eq: "img/telechargement/bas-surface/coloris"}}){
    edges {
      node {
        childImageSharp {
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
        }
      }
    }
}
`
export default BasSurfaceColorisPage